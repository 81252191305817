import lazySizes from 'lazysizes';
import React from "react";
import { ThemeProvider } from "styled-components";
import { RecoilRoot } from "recoil";
import "normalize.css";

import ScrollAware from "./new/ScrollAware";

import theme from "../theme";

const Root = ({ children }) => (
  <ThemeProvider theme={theme}>
    <RecoilRoot>
      <ScrollAware>{children}</ScrollAware>
    </RecoilRoot>
  </ThemeProvider>
);

export default Root;
