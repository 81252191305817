import React from "react";
import WebFont from "webfontloader";
import { registerLinkResolver } from "@prismicio/gatsby-source-prismic-graphql";

import Root from "./src/components/Root";
import { linkResolver } from "./src/utils/linkResolver";

registerLinkResolver(linkResolver);

export const wrapRootElement = ({ element }) => <Root>{element}</Root>;

export const onServiceWorkerUpdateReady = () => {
  const answer = window.confirm(
    `IPG DXTRA Health has been updated. Reload to display the latest version?`
  );
  if (answer === true) {
    window.location.reload();
  }
};

export const onInitialClientRender = () => {
  WebFont.load({
    typekit: {
      id: "izr4nnj",
    },
  });
};

export const shouldUpdateScroll = (routerProps) => {
  // if pagination page we don't want to scroll to top on route change
  if (
    routerProps.pathname.includes("thought-leadership") ||
    routerProps.pathname.includes("join-us")
  ) {
    return false;
  }
  
  // default behaviour: scroll to top on link change
  return true;
};
