export const colors = {
  primary: "#222222",
  blue: "#1445f2",
  blueFocus: "#0C31B4",
  blueHover: "#06289F",
  turquoise: "#1DC0DA",
  yellow: "#EBC01F",
  orange: "#F2994A",
  red: "#EB5757",
  redFocus: "#BF2626",
  redHover: "#992222",
  green: "#4AB212",
  black01: "#000000",
  black02: "#070707", // mobile nav bg
  black03: "#111111", // header bg
  grey01: "#1F1F1F",
  grey02: "#262626",
  grey03: "#868686",
  grey04: "#ECECEC",
  grey05: "#F2F2F2",
  white: "#ffffff",
  overlay: "rgba(0, 0, 0, 0.7)",
};
