import { css } from "styled-components";

export const breakpoints = {
  mobile: 480,
  tablet: 768,
  tabletLandscape: 1024,
  desktop: 1200,
  desktopLarge: 1440,
};

export const spacing = {
  xxxl: "48px",
  xxl: "28px",
  xl: "16px",
  l: "12px",
  m: "8px",
  s: "4px",
  xs: "2px",
};

export const withMargin = ({ margin = "" }) => {
  const margins = margin
    .split(" ")
    .filter(Boolean)
    .map(s => {
      if (Object.keys(spacing).includes(s)) {
        return spacing[s];
      } else if (s === "0") {
        return "0";
      } else if (s.indexOf("px") > 0) {
        return s;
      } else {
        return "";
      }
    });

  return css`
    margin: ${margins.join(" ")};
  `;
};

export const withPadding = ({ padding = "" }) => {
  const paddings = padding
    .split(" ")
    .filter(Boolean)
    .map(s => {
      if (Object.keys(spacing).includes(s)) {
        return spacing[s];
      } else if (s === "0") {
        return "0";
      } else if (s.indexOf("px") > 0) {
        return s;
      } else {
        return "";
      }
    });

  return css`
    padding: ${paddings.join(" ")};
  `;
};
