import { css } from "styled-components";

import { colors } from "./colors";
import { fontWeights, fontSizes, lineHeights, letterSpacing } from "./fonts";
import { breakpoints, spacing } from "./spacing";

export const theme = {
  spacing,
  breakpoints,
  colors,
  fontWeights,
  fontSizes,
  lineHeights,
  letterSpacing,
  elevation: {
    10: css`
      box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
    `,
    25: css`
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.25);
    `,
    50: css`
      box-shadow: 0 3px 6px rgba(0, 0, 0, 0.35);
    `,
    100: css`
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.35);
    `,
  },
  zIndex: {
    top: 9999,
    navigation: 999,
    swipe: 300,
    content: 200,
    section: 100,
    background: 99,
    bottom: 10,
  },
};

export default theme;
