/*eslint no-unused-vars: ["error", { "varsIgnorePattern": "_" }]*/
import { useEffect, useRef } from "react";
import { atom, selector, useRecoilState } from "recoil";

const SWIPE_ANGLE = 28.5;

export const viewportState = atom({
  key: "viewport",
  default: {
    width: 0,
    height: 0,
  },
});

export const browserState = atom({
  key: "browser",
  default: {
    isMobile: false,
    isIE: false,
    isSafari: false,
    isChrome: false,
    isRetina: false,
    isMac: false,
    useFallbacks: false,
  },
});

export const calculateTriangle = (height, angle) => {
  const c = height / Math.cos((Math.PI / 180) * angle);
  const b = Math.ceil(Math.sqrt(c * c - height * height));

  return {c, b}
}

export const getWindowScroll = () => {
  return {
    x: window.pageXOffset || document.documentElement.scrollLeft,
    y: window.pageYOffset || document.documentElement.scrollTop
  };
}

export const clipState = selector({
  key: "clipPoints",
  get: ({ get }) => {
    const { width, height, } = get(viewportState);
    const { isIE, isSafari, isMobile } = get(browserState);

    if (width === 0 || height === 0) {
      return {
        points: "",
        translateX: 0,
        offset: 0,
        conatinerWidth: 0,
        multiplier: 0,
        scrollMax: 0,
      };
    }
    const {c, b} = calculateTriangle(height, SWIPE_ANGLE);
    const offset = b - 142;

    const x = width + b;
    const y = isSafari ? height + 160 : height;

    let points;
    let translateX;
    let scrollMax;
    let containerWidth;

    if (isIE) {
      translateX = (width + (offset - 142)) * -1;
      containerWidth = width * 2 + offset;
      scrollMax = width + (offset - 142);
      points = `0,0 ${width},0 ${x},${y} 0,${y} 0,0`;
    } else {
      translateX = -offset;
      containerWidth = x;
      scrollMax = containerWidth;
      points = `0,0 ${x},0 ${x},${y} ${b},${y} 0,0`;
    }

    let multiplier = 0;
    if (isMobile) {
      multiplier = (y / x);
    } else if (y > x){
      multiplier = (y / x) * 2;
    } else {
      multiplier = (x / y) * 2;
    }

    return {
      points,
      translateX,
      offset: b,
      containerWidth,
      multiplier,
      scrollMax,
    };
  },
});

export const ScrollAware = ({ children }) => {
  const browserWindow = typeof window !== "undefined" ? window : undefined;

  const [_browser, setBrowser] = useRecoilState(browserState);
  const [viewport, setViewport] = useRecoilState(viewportState);
  const tick = useRef(false);

  const resizeWindow = () => {
    if (window.innerHeight === viewport.width) {
      tick.current = false;
      return;
    }

    if (browserWindow.innerWidth < 768) {
      setViewport({
        width: browserWindow.innerWidth,
        height: document.getElementById('mobile-helper').clientHeight,
      });
    } else {
      setViewport({
        width: browserWindow.innerWidth,
        height: browserWindow.innerHeight,
      });
    }

    tick.current = false;
  }

  const onResize = () => {
    if (!browserWindow) {
      return;
    }

    if (!tick.current) {
      tick.current = true;

      if (window.requestAnimationFrame) {
        window.requestAnimationFrame(resizeWindow);
      } else {
        setTimeout(resizeWindow, 66);
      }
    }
  };

  useEffect(() => {
    window.addEventListener("resize", onResize);
    window.addEventListener('touchmove', onResize);

    return () => {
      window.removeEventListener("resize", onResize);
      window.removeEventListener('touchmove', onResize);
    };
  });

  // browser handler
  useEffect(() => {
    if (!browserWindow) {
      return;
    }

    const isMobile = browserWindow.innerWidth < 768;

    const isChrome = browserWindow && /Google Inc/.test(navigator.vendor);

    const isSafari =
      browserWindow &&
      (/constructor/i.test(window.HTMLElement) ||
        (function(p) {
          return p.toString() === "[object SafariRemoteNotification]";
        })(
          !window["safari"] ||
            (typeof safari !== "undefined" && safari.pushNotification)
        ));

    const isIE =
      /MSIE 10/i.test(navigator.userAgent) ||
      /MSIE 9/i.test(navigator.userAgent) ||
      /rv:11.0/i.test(navigator.userAgent) ||
      /Edge\//i.test(navigator.userAgent);

    if (isIE) {
      console.log(
        "You are viewing this site in a legacy browser and unfortuantley this will drammatically reduce your experience while browsing. It is strongly advised that you upgrade your browser to view this site."
      );
    }

    const isMac = navigator.platform.toUpperCase().indexOf("MAC") >= 0;

    const isRetina =
      browserWindow &&
      ((window.matchMedia &&
        (window.matchMedia(
          "only screen and (min-resolution: 124dpi), only screen and (min-resolution: 1.3dppx), only screen and (min-resolution: 48.8dpcm)"
        ).matches ||
          window.matchMedia(
            "only screen and (-webkit-min-device-pixel-ratio: 1.3), only screen and (-o-min-device-pixel-ratio: 2.6/2), only screen and (min--moz-device-pixel-ratio: 1.3), only screen and (min-device-pixel-ratio: 1.3)"
          ).matches)) ||
        (window.devicePixelRatio && window.devicePixelRatio > 1.3));

    setBrowser({
      isMobile,
      isChrome,
      isSafari,
      isIE,
      isRetina,
      isMac,
      useFallbacks: (isMac && isRetina && isChrome) || isSafari || isIE,
    });

    onResize();
  }, [browserWindow]);

  return children;
};

export default ScrollAware;
