// -- The Link Resolver
// This function will be used to generate links to Prismic documents
// As your project grows, you should update this function according to your routes

export const linkResolver = (doc) => {
  if (doc.type === "ipg_dxtra_erecruiting_privacy_notice_country") {
    return `/erecruitment-privacy-notice/${doc.uid}`;
  }

  // this might be incorrect...
  if (doc.type === "hygiene_page_templates") {
    return `/${doc.uid}`;
  }

  if (doc.type === "contact_us") {
    return `/contact`;
  }

  // Generic route fallback
  return `/${doc.uid}`;
};
